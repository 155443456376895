import {structureToElementsConverter} from "./structureToElementsConverter";
import _ from "lodash";
import {cityProvinces} from "../../scenes/SurveyCollector/components/CollectorPanel/Tabs/CollectorPanelForm";

export default class TextToTranslationsConverter{
	constructor(structure, text) {
		this.text = text;
		const elements = structureToElementsConverter(structure);
		this.sets = elements.sets;
		this.pages = elements.pages;
		this.blocks = elements.blocks;
		this.questions = elements.questions;
	}

	convert = () => {
		let translationsObject = this.text.split("\n").filter(translation => translation);

		translationsObject = _.uniqBy(translationsObject, function (translation) {
			return translation;
		});

		let languages = translationsObject.filter(s => {
			return s.startsWith("{%");
		});
		languages = languages.map(s => {
			let language = s.replace("{%", "");
			return language.replace("%}", "");
		});

		let currentLang;
		let currentQuestion = null;
		let translations = [];
		let translation;
		translationsObject.forEach(s => {
			if (s.startsWith("{%")) {
				let language = s.replace("{%", "");
				language = language.replace("%}", "");
				currentLang = languages.find((entry) => {
					return entry === language;
				});
			}

			if (s.startsWith("Survey")) {
				translation = this.createBasicTranslation(s, "Survey", currentLang, 'survey');
				translations.push(translation)
			} else if (s.startsWith("Q")) {
				translation = this.createBasicTranslation(s, "Q", currentLang, 'question');
				currentQuestion = this.questions[translation.question - 1];
				translation.question = currentQuestion.id;
				translations.push(translation)
			} else if (s.startsWith("A")) {
				translation = this.createBasicTranslation(s, "A", currentLang, 'answer')
				translation.answer = currentQuestion.answers[translation.answer - 1];
				translations.push(translation)
			} else if (s.startsWith("R")) {
				translation = this.createBasicTranslation(s, "R", currentLang, 'row')
				translation.row = currentQuestion.rows[translation.row - 1];
				translations.push(translation)
			} else if (s.startsWith("C")) {
				translation = this.createBasicTranslation(s, "C", currentLang, 'column')
				translation.column = currentQuestion.columns[translation.column - 1];
				translations.push(translation)
			} else if (s.startsWith("E")) {
				translation = this.createBasicTranslation(s, "E", currentLang, 'excluding')
				translation.excluding = currentQuestion.excludings[translation.excluding - 1];
				translations.push(translation)
			} else if (s.startsWith("B")) {
				translation = this.createBasicTranslation(s, "B", currentLang, 'basket')
				translation.basket = currentQuestion.baskets[translation.basket - 1];
				translations.push(translation)
			} else if (s.startsWith("S")) {
				translation = this.createBasicTranslation(s, "S", currentLang, 'scalePoint')
				translation.scalePoint = currentQuestion.scalePoints[translation.scalePoint - 1];
				translations.push(translation)
			}


		});

		return {translations: translations, languages: languages} || [];
	}

	createBasicTranslation = (string, prefix, lang, type) => {
		let translation = {
			content: "",
			lang: lang,
			field: "content",
			type: type
		}
		const split = string.split("::");
		const replaceNumber = Number(split[0].replace(prefix, ""));
		if (replaceNumber) {
			translation[type] = replaceNumber
		}

		if (split.length > 1) {
			translation.content = split[1];
		}

		if (split.length > 2) {
			translation.field = split[2];
		}

		return translation;
	}
}